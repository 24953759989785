import { Controller } from "@hotwired/stimulus"

// Should be used for longer running actions triggered by
// clicking on an icon wrapped in a link. Replaces icon with a
// spinner and disables the link action to prevent duplicate submission.
// Optionally disables other elements (elementsSelector).
export default class extends Controller {
    static values = {
        elementsSelector: String,
    }

    connect() {
        this.element.addEventListener("click", this.handleClick.bind(this))

        $(document).on('confirm:complete', (event) => {
          if (event.originalEvent.target == this.element && !event.originalEvent.detail[0]) {
            $(this.element).data('clicked', false)
            $(this.element).removeClass('btn-link disabled');
            $(this.element).find('em').removeClass('bi-spin')
            $(this.elementsSelectorValue).each(
              (idx, elem) => {
                  $(elem).removeClass('btn-link disabled text-muted')
              })
          }
      });
    }

    disconnect() {
      this.element.removeEventListener("click", this.handleClick.bind(this))
    }

    handleClick(event) {
      if(!$(this.element).data('clicked')) {
        $(this.elementsSelectorValue).each(
          (idx, elem) => {
              if (elem !== this.element) {
                  $(elem).addClass('btn-link disabled text-muted')
              }
          })

        $(this.element).data('clicked', true)
        $(this.element).addClass('btn-link disabled ');

        const icon = $(this.element).find('em')

        $(icon).removeClass((idx, className) => {
            return (className.match (/(^|\s)bi-\S+/g) || []).join(' ')
        })
        $(icon).addClass('bi-arrow-repeat')
        $(icon).addClass('bi-spin')
      } else {
        event.preventDefault()
      }
    }
}
